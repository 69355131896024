import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { isoParse } from "d3-time-format";
import useBaseUtils from "~/functions/useBaseUtils";
import useGuruUtils from "~/functions/useInvestorGuruUtils";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import { defaultUsdObj } from "~/utils/constants/objects";
export default defineComponent({
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev,
      freeTier = _useBaseUtils.freeTier,
      I18nFn = _useBaseUtils.I18nFn;
    var _useGuruUtils = useGuruUtils(),
      permIdObj = _useGuruUtils.permIdObj;
    var _useNumberFormatters = useNumberFormatters(),
      formatPercentTwoDecimals = _useNumberFormatters.formatPercentTwoDecimals,
      formatInt = _useNumberFormatters.formatInt;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate;
    return {
      refCode: refCode,
      dev: dev,
      formatDate: utcEpochToShortDate,
      formatPercent: formatPercentTwoDecimals,
      formatInt: formatInt,
      freeTier: freeTier,
      permIdObj: permIdObj,
      defaultUsdObj: defaultUsdObj,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      useHoldingDatePrice: 0,
      options: {},
      type: [],
      activeColumns: [{
        text: "Investor Name",
        value: "Name",
        align: "center",
        filterable: false,
        sortable: false
      }, {
        text: "Value of Shares Held (MM)",
        value: "SharesHeldValue",
        align: "center",
        filterable: false,
        sortable: false
      }, {
        text: "% Of Shares Outstanding Held",
        value: "PctOfSharesOutstanding",
        align: "center",
        filterable: false,
        sortable: false
      }, {
        text: "# Shares Held",
        value: "SharesHeld",
        align: "center",
        filterable: false,
        sortable: false
      }, {
        text: "Change in # of Shares Held",
        value: "SharesHeldChange",
        align: "center",
        filterable: false,
        sortable: false
      }, {
        text: "% Change in # of Shares Held",
        value: "SharesHeldChangePct",
        align: "center",
        filterable: false,
        sortable: false
      }, {
        text: "This Holding as % of Firm's Portfolio",
        value: "HoldingsPctofPortfolio",
        align: "center",
        filterable: false,
        sortable: false
      }, {
        text: "Holdings Date",
        value: "HoldingsDate",
        align: "center",
        filterable: false,
        sortable: false
      }, {
        text: "Investor Type",
        value: "InvestorType",
        align: "center",
        filterable: true,
        sortable: false
      }],
      fetching: false,
      optionsPage: 1,
      itemsPerPage: 25,
      fetchAttempts: 0,
      serverItemsLength: 0,
      disablePagination: false
    };
  },
  computed: {
    transColumns: function transColumns() {
      var _this = this;
      return this.activeColumns.map(function (c) {
        return _objectSpread(_objectSpread({}, c), {}, {
          text: _this.I18nFn(c.text)
        });
      });
    },
    currencyToggle: {
      get: function get() {
        return this.$store.state.ciq.quoteCurrencyToggle;
      },
      set: function set(value) {
        this.$store.commit("ciq/setToggle", {
          type: "quoteCurrencyToggle",
          value: value
        });
      }
    },
    activeCurrency: function activeCurrency() {
      return this.currencies[this.currencyToggle] || this.defaultUsdObj;
    },
    currencies: function currencies() {
      return this.$store.state.ciq.quoteCurrencies; // if (
      //   this.defaultCurrency.code === "USD" ||
      //   this.defaultCurrency.code === "loading.."
      // ) {
      //   return [this.defaultCurrency]
      // } else {
      //   return [this.defaultCurrency, { code: "USD", name: "US Dollar" }]
      // }
    },
    quotes: function quotes() {
      return this.$store.state.ciq.quotes.price || [];
    },
    last: function last() {
      if (this.quotes.length > 0) {
        var lastDay = this.quotes[this.quotes.length - 1];
        var secondToLast = this.quotes[this.quotes.length - 2]; // const c = this.currencyToggle
        //   ? lastDay.c / lastDay.pc
        //   : lastDay.c
        var c = lastDay.c;
        var pc = lastDay.pc || 1;
        var latestTime = this.formatDate.format(lastDay.d) + " Close";
        if (secondToLast) {
          var previousClose = this.currencyToggle ? secondToLast.c / secondToLast.pc : secondToLast.c;
          var change = c - previousClose;
          var changePercent = change / previousClose; // TODO: Make time formatting better
          return {
            c: c,
            pc: pc,
            change: change,
            changePercent: changePercent,
            latestTime: latestTime
          };
        } else {
          return {
            c: c,
            pc: pc,
            change: "",
            changePercent: "",
            latestTime: latestTime
          };
        }
      }
      return {
        c: 0,
        pc: 1,
        change: "",
        changePercent: "",
        latestTime: ""
      };
    },
    resData: function resData() {
      var defaultObj = {
        Shareholders: {
          Investor: []
        },
        Symbol: {},
        TotalShareholderCount: 1
      };
      if (this.$store.state.trkd.tickerShareholders) {
        var rawData = Object.hasOwn(this.$store.state.trkd.tickerShareholders, "Shareholders") ? this.$store.state.trkd.tickerShareholders : defaultObj;
        return rawData;
      } else {
        return defaultObj;
      }
    },
    rawShareholders: function rawShareholders() {
      var all = this.type.length === 0;
      var types = this.type;
      return this.resData.Shareholders.Investor.filter(function (i) {
        return types.includes(i.InvestorType) || all;
      });
    },
    investorTypes: function investorTypes() {
      var types = this.resData.Shareholders.Investor.map(function (s) {
        return s.InvestorType;
      });
      var typeCount = types.reduce(function (acc, t) {
        if (acc[t]) {
          acc[t] = acc[t] + 1;
          return acc;
        } else {
          acc[t] = 1;
          return acc;
        }
      }, {});
      var typeArray = Object.keys(typeCount).map(function (t) {
        return {
          name: t,
          number: typeCount[t]
        };
      });
      var sortedTypes = typeArray.sort(function (a, b) {
        return b.number - a.number;
      });
      return sortedTypes;
    },
    shareholders: function shareholders() {
      var _this2 = this;
      var itemsPerPage = this.options.itemsPerPage || 25;
      var optionsPage = this.options.page || 1;
      var bottomIdx = (optionsPage - 1) * itemsPerPage;
      var topIdx = itemsPerPage - 1 + bottomIdx;
      var shareHolders = this.rawShareholders.length === 0 ? [] : this.rawShareholders.map(function (d) {
        return {
          Name: d.Name,
          SharesHeldValue: d.Holding.SharesHeldValue,
          PctOfSharesOutstanding: d.Holding.PctOfSharesOutstanding,
          SharesHeld: d.Holding.SharesHeld,
          SharesHeldChange: d.Holding.SharesHeldChange,
          SharesHeldChangePct: d.Holding.SharesHeldChangePct,
          HoldingsPctofPortfolio: d.Holding.HoldingsPctofPortfolio,
          HoldingsDate: isoParse(d.Holding.HoldingsDate),
          InvestorType: d.InvestorType,
          permId: d.InvestorPermId,
          pctChange: _this2.calculateChange(d.Holding)
        };
      });
      return shareHolders.filter(function (s, idx) {
        return idx >= bottomIdx && idx <= topIdx;
      });
    },
    shareholderCount: function shareholderCount() {
      return this.resData.TotalShareholderCount;
    },
    ticker: function ticker() {
      return Object.hasOwn(this.$store.state.trkd.ticker, "TRName") ? this.$store.state.trkd.ticker : {};
    },
    error: function error() {
      return this.$store.state.trkd.shareholdersError;
    },
    initialLoad: function initialLoad() {
      return this.$store.state.trkd.fetchingShareholders;
    },
    formatCurrency: function formatCurrency() {
      var lang = navigator.language || "default";
      var currencyIso = this.activeCurrency.code || "USD"; // this needs to be pulled from the dataset
      var min = 1; // this needs to sync with the UI on formatting
      var max = 1;
      return new Intl.NumberFormat(lang, {
        style: "currency",
        currency: currencyIso,
        minimumFractionDigits: min,
        maximumFractionDigits: max
      });
    },
    formatCurrencyUSD: function formatCurrencyUSD() {
      var lang = navigator.language || "default";
      var currencyIso = "USD"; // this needs to be pulled from the dataset
      var min = 1; // this needs to sync with the UI on formatting
      var max = 1;
      return new Intl.NumberFormat(lang, {
        style: "currency",
        currency: currencyIso,
        minimumFractionDigits: min,
        maximumFractionDigits: max
      });
    },
    footerProps: function footerProps() {
      var itemsPerPageOptions = this.itemsPerPageOptions;
      var disablePagination = this.disablePagination;
      return {
        itemsPerPageOptions: itemsPerPageOptions,
        disablePagination: disablePagination
      };
    },
    itemsPerPageOptions: function itemsPerPageOptions() {
      var baseItemsPerPageOptions = [5, 10, 25, 50, 100];
      var filteredLength = this.rawShareholders.length;
      var cappedLength = filteredLength <= 200 ? filteredLength : 200;
      var itemsPerPageOptions = baseItemsPerPageOptions.filter(function (num) {
        return num <= filteredLength;
      });
      if (filteredLength > 0 && !itemsPerPageOptions.includes(cappedLength)) {
        itemsPerPageOptions.push(cappedLength);
      }
      if (itemsPerPageOptions.length === 1 && !itemsPerPageOptions.includes(1)) {
        itemsPerPageOptions.push(1);
      }
      var sortedItems = itemsPerPageOptions.sort(function (a, b) {
        return a - b;
      }); // logging code
      if (this.dev) {
        console.log("filtered length of ".concat(filteredLength, ", capped length of ").concat(cappedLength)); // console.log("Raw shareholders: ", this.rawShareholders)
        console.log("sortedItems: ", sortedItems);
      }
      return sortedItems;
    }
  },
  watch: {
    options: {
      handler: function handler(newVal, oldVal) {
        if (newVal) {
          // fetchLength is the total length / # of investors fetched from trkd
          var fetchLength = this.resData.Shareholders.Investor.length; // filteredLength is the length of all of the fetched investors filtered for
          // the users desired investor type
          var filteredLength = this.rawShareholders.length; // skip (page, starting at 1) and limit (itemsPerPage) from the options object
          var optionsPage = newVal.page || 1;
          var itemsPerPage = newVal.itemsPerPage || 25; // lets verify that the bottom and top index are correct
          // in the event page = 2 & items per page is 25
          // we just displayed 0 - 24
          // now we want to display 25 - 29
          // items per page 10
          // page 1 -> 0 - 9
          // page 2 -> 10 - 19
          // items per page 1
          // page 1 -> 0 - 0
          // page 2 -> 1 - 1
          // items per page 5
          // page 1 -> 0 - 4
          // page 2 -> 5 - 9
          var bottomIdx = (optionsPage - 1) * itemsPerPage; // this feels right
          var topIdx = itemsPerPage - 1 + bottomIdx;
          if (fetchLength === this.shareholderCount) {
            this.serverItemsLength = filteredLength;
          } else {
            this.serverItemsLength = this.shareholderCount;
          }
          if (this.dev) {
            console.log("tableOptions changed from ".concat(oldVal.page, " page and ").concat(oldVal.itemsPerPage, " items per page"));
            console.log("tableOptions changed to ".concat(newVal.page, " page and ").concat(newVal.itemsPerPage, " items per page"));
            console.log("filted length of ".concat(filteredLength, " with bottomIdx of ").concat(bottomIdx, " and topIdx of ").concat(topIdx));
          }
          if (newVal.page !== oldVal.page || oldVal.itemsPerPage !== newVal.itemsPerPage) {
            var toAdd = filteredLength === 0 ? 1 : 0;
            if (topIdx + toAdd >= filteredLength) {
              if (fetchLength < this.shareholderCount && !this.initialLoad) {
                var payload = {
                  start: fetchLength + 1
                }; // console.log("fetching shareholders with: ", payload)
                this.$store.dispatch("trkd/fetchShareholderReport", payload);
                this.fetching = true;
              } else {// console.log("fetch avoided as all data has been fetched")
                // confirm("You've fetched all the ownership data!")
              }
            } else {
              // instead of blindly setting optionsPage and itemsPerPage
              // make sure to keep these in range of filtered length
              // being mindful of the total amount that can be shown... got it.
              // also I think if I look at the console footer is calculated first
              this.optionsPage = optionsPage;
              this.itemsPerPage = this.closestInArray(itemsPerPage);
              this.fetching = false;
            }
          } else {
            // this block executes after data has been fetched and
            // the table reloads
            /**
                         * When the table reloads, filteredLength is the # of Investors
                         * for whatever filter the user has implemented
                         * topIdx is the user's desired # of records to display
                         * if the topIdx is less than the filteredLength
                         * then the page and itemsPerPage being passed into this block
                         * are less than the total number of available records and should
                         * be set as the values to determine the displayed range
                         *
                         * If the topIdx is greater than the number of available records
                         * then a manipulation needs to occur
                         */ // const footerItemsPerPage = this.footer.itemsPerPageOptions
            if (topIdx - 1 > filteredLength) {
              // adjust page and items per page to be inclusive of the available data
              if (itemsPerPage > filteredLength) {
                // then you need to decrease the itemsPerPage
                // console.log("ItemsPerPage greater than filteredLength")
                this.optionsPage = 1;
                this.itemsPerPage = this.closestInArray(filteredLength);
              } else {
                // console.log("filteredLength greater than items per page")
                // find the whole number of the division
                // set it equal to the page plus one
                var wholePart = Math.floor(filteredLength / itemsPerPage);
                this.optionsPage = wholePart + 1; // find the remainder
                var remainingLength = filteredLength - wholePart * itemsPerPage;
                if (remainingLength < itemsPerPage) {
                  this.itemsPerPage = this.closestInArray(remainingLength);
                } else {
                  this.itemsPerPage = this.closestInArray(itemsPerPage);
                } // set the itemsPerPage to that Remainder
                // const remainingLength =
                //   filteredLength - optionsPage * itemsPerPage
                // if (remainingLength > 0) {
                //   this.optionsPage = optionsPage
                //   this.itemsPerPage = remainingLength
                // } else if (remainingLength < 0) {
                //   this.optionsPage = optionsPage - 1 // could get weird if optionsPage is 1
                //   this.itemsPerPage = itemsPerPage + remainingLength
                // } else {
                //   // remainingLength === 0
                //   this.optionsPage = optionsPage - 1
                //   this.itemsPerPage = itemsPerPage
                // }
                // this.optionsPage = optionsPage
                // this.itemsPerPage = itemsPerPage
              }
            } else {
              // can blindly assign desired page and items per page
              this.optionsPage = optionsPage;
              this.itemsPerPage = this.closestInArray(itemsPerPage);
            }
            this.fetching = false;
          }
        }
      },
      deep: true
    },
    initialLoad: function initialLoad(newVal) {
      // console.log("initialLoad Value: ", newVal)
      if (newVal === true || newVal === false) {
        console.log("disable pagination toggled to : ", newVal);
        this.disablePagination = newVal;
      }
    }
  },
  methods: {
    heldValue: function heldValue(item) {
      var value = item.SharesHeldValue / 1000000;
      var lastPrice = this.last.c;
      var pc = this.last.pc || 1; // force everything to USD
      if (lastPrice > 0 && !this.useHoldingDatePrice) {
        var sharesHeld = item.SharesHeld;
        return this.formatCurrencyUSD.format(sharesHeld * (lastPrice / pc) / 1000000);
      }
      return this.formatCurrencyUSD.format(value);
    },
    calculateChange: function calculateChange(item) {
      // assume item.SahresHeldValue is USD
      // everything changes if it is not..
      try {
        var v0 = item.SharesHeldValue; // is this is millions?
        var s0 = item.SharesHeld; // are these numbers?
        if (s0) {
          var p0 = v0 / s0;
          var lastPrice = this.last.c;
          var pc = this.last.pc || 1;
          var p1 = lastPrice / pc;
          return (p1 - p0) / p0; // % change going from holdings date to latest
          // return (p0 - p1) / p1 // % change going from latest close to holdings date
        }
      } catch (error) {
        console.error("error calculating shares held value percent change");
      }
    },
    displayInt: function displayInt(_int) {
      if (_int === 0) {
        return "-";
      }
      if (_int > 0) {
        return this.formatInt.format(_int);
      }
      return "(".concat(this.formatInt.format(Math.abs(_int)), ")");
    },
    displayPercent: function displayPercent(pct) {
      if (pct === 0) {
        return "-";
      }
      if (pct > 0) {
        return "".concat(this.formatPercent.format(pct / 100));
      }
      return "(".concat(this.formatPercent.format(Math.abs(pct) / 100), ")");
    },
    changeClass: function changeClass(val) {
      if (val && val < 0) {
        return "red--text";
      }
    },
    closestInArray: function closestInArray(goal) {
      var arr = this.itemsPerPageOptions;
      var closest = arr.reduce(function (prev, curr) {
        // this will always return the higher number
        return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
      }, 0);
      if (this.dev) {// console.log("closest: ", closest)
      }
      return closest;
    },
    filterSelected: function filterSelected() {
      if (this.dev) {
        console.log("@select on filter called");
      }
      this.options.page = 1;
    }
  }
});